<template>
  <router-view v-if="module" :project.sync="project" :module.sync="module" />
  <span v-else />
</template>

<script>

import $api from '@/libs/OAuth2/services/api';

export default {
  name: 'module-frame',
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      module: null,
    };
  },
  watch: {
    module: {
      handler (newVal) {
        this.$root.module = newVal;
      },
      immediate: true,
    },
  },
  destroyed () {
    this.$root.module = null;
  },
  beforeRouteEnter (to, from, next) {
    const id = to.params?.moduleId;
    if (!id) {
      return next(from);
    }

    $api.fetchModule(id)
      .then(res => res.data)
      .then(data => {
        if (!data) {
          return next(from);
        }

        next(vm => {
          vm.module = data;
        });
      })
      .catch(() => next(from))
    ;
  },
};

</script>
